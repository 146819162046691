/*------------SIDEBAR MENU------------*/
.childrenmenu {
  padding: 10px 0; 
  background-color: #505050;
  color: white;
}

.input-connection-name {
	color: white !important;
	background-color: transparent !important;
	text-overflow: ellipsis;
}

.input-connection-name:focus {
	outline-color: white !important;
}

.dashboard-v2-shadowbox{
	box-shadow: 3px 3px 10px -4px rgba(158,158,158,1);
}

.drag-column{
  border-right: solid 2px transparent;
  transition: border-right .5s;
}

.drag-column:hover{
  border-right: solid 2px white;
}
/*------------END of SIDEBAR MENU------------*/

.chartTitle {
  font-weight: bold;
}
.opacity-animation{
	animation:opacityText 1.5s infinite;
}
@keyframes opacityText{
	0%{		opacity: 0;		}
  16.7% {opacity: 0.3}
  33.3% {opacity: 0.6}
	25%{	opacity: 0.5;		}
	50%{	opacity: 1;		}
	/* 75%{	opacity: 0.5;		} */
	66.6%{	opacity: 0.6;		}
	83.2%{	opacity: 0.3;		}
	100%{	opacity: 0;	}
}

/* Source: https://stackoverflow.com/a/29512692 */
.chart-tabular-table {
  white-space: nowrap;
}
