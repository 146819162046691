// routes
@import "role-manager/index";
@import "user/index";

// components
@import "components/header-main";
@import "components/page-navbar";
@import "components/wizard";

@font-face {
  font-family: myfonts;
  src: url('/fonts/OpenSans.ttf');
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

a:hover {
  text-decoration:none;
}

.bump {
  margin-top: 20px;
}

.modal .modal-body {
  max-height: 400px;
  overflow-y: auto;
}

.modal-header{
  border-bottom: 0px;
  background-color: #f9f9f9;
}

.modal-footer{
  border-top: 0px;
}

.loading-pane {
  margin: 20% auto;
}

.loading-pane-padding {
  padding: 25%;
}

.loading-message {
  text-align: center;
  color: #aaa;
  padding-bottom: 10px;
}

.spinner {
  background: url('/images/ajax-loader.gif') no-repeat;
  height: 31px;
  width: 31px;
  margin: 0 auto;
}

.menu-container {
  padding-bottom: 50px
}

.right-inner-addon {
  position: relative;
}

.right-inner-addon input {
  padding-right: 30px;    
}

.right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  pointer-events: none;
}

.lg .right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  pointer-events: none;
  margin-top: 23px;
  margin-right: 10px;
}

.navbar .divider-vertical {
  height: 50px;
  margin: 0 9px;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #f2f2f2;
}

.navbar-inverse .divider-vertical {
  border-right-color: #222222;
  border-left-color: #111111;
}

@media (max-width: 767px) {
.navbar-collapse .nav > .divider-vertical {
    display: none;
  }
}

.menu-app-list {
  padding: 10px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-app-list:hover {
  background-color: #CECCCC;
  transform: scale(1.2);
}

.auth-form {
  padding: 40px 0;
}

.float-none {
  float: none;
}

.margin-0 {
  margin: 0px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-23 {
  margin-top: 23px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bot-5 {
  margin-bottom: 5px;
}

.margin-bot-10 {
  margin-bottom: 10px;
}

.margin-bot-20 {
  margin-bottom: 20px;
}

.margin-bot-40 {
  margin-bottom: 40px;
}

.margin-right--4 {
  margin-right: -4px;
}

.dropdown-icon-error {
  margin-right: 6px;
}

.margin-right-20 {
  margin-right: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.remove {
  color: #d9534f;
}

.remove:hover {
  color: #c9302c;
}

.red {
  color: #d9534f;
}

.white {
  color: white;
}

.grey {
  color: #777;
}

.border-radius-4 {
  border-radius: 4px;
}

.padding-0 {
  padding: 0px;
}

#role-list > li {
    display: inline-block;
    /* You can also add some margins here to make it look prettier */
    zoom:1;
    *display:inline;
    /* this fix is needed for IE7- */
}

// ul > li{
//     display: inline-block;
//     zoom:1;
//     display:inline; /* this fix is needed for IE7- */
// }

.assigned-icon {
  min-height: 20px;
  padding: 10px;
  margin-bottom: 20px;
}

.modal-icon:hover {
  border: 1px solid #606060;
  background-color: #F0F0F0;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  -ms-word-break: normal;
  word-break: normal;
}

.dropdown-limit {
  top: 2px;
  position: relative;
}

.break {
  word-break: break-all;
}

.table-responsive {   
  width: 100%;
  margin-bottom: 15px;
  overflow-x: auto;   
  overflow-y: hidden;     
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.fixed {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

// hide spinner from number-input
input[type=number] {-moz-appearance: textfield;}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user-roles-thin-top-bar {
  border-top: 3px solid #2b2b2b
}
