/* components/w3office-forms/standard-components/datetime-picker  */
.pikaday-picker > input {
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	padding-left: 10px;
}

.pikaday-picker > input:disabled {
	background-color: #e9ecef;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
/* END OF components/w3office-forms/standard-components/datetime-picker  */