.scheduler-form-btn {
  float: left;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.scheduler-form-btn > input[type='checkbox'] {
  position: absolute;
  clip: rect(0,0,0,0);
}

.btn-group[data-toggle='buttons'] {
  display: inline-block;
}

// .scheduler-form-btn > input {
//   display: none;
// }
// .scheduler-form-btn > label {
//   margin-bottom: 0;
// }