/*
* ==========================================
* top-menubar component
* ==========================================
*/
$main-color: #f5f6f8;

.core-top-menubar {
  height: 3rem;
  background-color: $main-color;
}

.core-top-menubar-li > .dropdown-toggle:after {
  content:none;
}

.core-top-menubar-li > .dropdown-menu {
  z-index: 1035;
}

.dropdown-submenu {
  position: relative;
  z-index: 1035;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
}
// END OF top-menubar

#core-top-navbar {
  border: none;
  max-height: 50px;
}

.sidebarText a {
  color: inherit;
  padding-right: 15px;
  padding-left: 15px;
}

.sidebarText a:-webkit-any-link {
  text-decoration: none;
}

.sidebarText a:-webkit-any-link {
  text-decoration: none;
}

.rotate-up {
  font-size: 1.4rem;

  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.rotate-down {
  font-size: 1.4rem;

  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

// #wrapper section

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  // layering (for show/hide sidebar on mobile)
  position: relative;
  z-index: 1001;
}

#headernavdesktop-accordion {
  bottom: 0;
  color: #333;
  float: left;
  margin-bottom: 0;
  // margin-top: 50px;
  margin-right: 15px;
  max-height: 100%;
  min-height: 100vh;
  overflow: auto;
  padding: 0;
  padding-top: 50px;
  width: 100%;
}

.sidebar-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0008;
  z-index: 199;
  display: none;
}

@media (max-width: 575.98px) {
  .hide-sidebar {
    margin-left: -250px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .show-sidebar {
    margin-left: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width:50%;
    min-width: 180px;
    max-width: 240px;
    position: fixed;
    z-index: 1001;
  }
}

@media (min-width: 768px) {
  #headernavdesktop-accordion {
    margin-left: 0;
  }

  .sidebar-expanded {
    // animation
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .sidebar-minimized {
    max-width: 5vw !important;
  
    // animation
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .show-sidebar {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .sidebar-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #0008;
    z-index: 199;
    display: none !important;
  }

  .sidebar-minimized > .panel > .sidebarText {
    display: none !important;
  }
  
  .sidebarIcon {
    display: none !important;
    font-size: 18px;
  }
  
  .sidebar-minimized > .panel > .sidebarIcon {
    display: block !important;
  }

  .sidebar-minimized > .sidebar-appId  {
    display: none;
  }

  .sidebar-minimized > .sidebar-copyright {
    display: none;
  }
}

#toggle-menu-button {
  position: absolute;
  top:50px;
  background-color: #eecc99;
  z-index:1;
  width: 20px;
}

.toggle-menu-button-expanded {
  left: 16.5vw !important;
}

.toggle-menu-button-minimized {
  left: 5vw !important;
}

.main-container-expanded {
  max-width: 94vw;
}

.child-menu-title {
  overflow-wrap:break-word;
}
