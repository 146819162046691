.node-item {
	top: 20px;
	left: 20px;
}

.process-item {
  position: absolute;
  float: left;
  box-sizing: border-box;
}

.node-option {
  position: absolute;
  font-size: 15px !important;
  right: 0;
}

.node-move {
  cursor: move;
  top: 0;
}

.node-config {
  cursor: pointer;
  top: 17px;
}

.node-type {
  top: 5px;
  left: 5px;
}

.node-connect {
  cursor: pointer;
  /*width: 44px;*/
  /*height: 44px;*/
  position: absolute;
  border-radius: 5px;
  border: 4px solid;
  top: -5px;
  left: -5px;
  border-color: rgba(0, 0, 0, 0);
}

.node-activity {
	width: 150px;
  padding-top         : 5px;
  border-style        : solid;
  border-color        : #424242;
  border-radius       : 5px;
  padding-left        : 3px;
  padding-bottom      : 7px;
  text-align          : justify;
  overflow			  		: hidden;
  white-space         : normal;
  text-align          : center;
  -moz-text-align-last: center; /* Firefox 12+ */
  text-align-last     : center;
  resize              : none;
}

.node-task {
  border-width : 2px;
}

.node-sub {
  height: 69px;
  padding-bottom: 24px;
}

.node-activity-symbol {
	position: absolute;
  left: 66px;
  top: 45px;
  cursor: pointer;
}

.node-call {
  border-width : 4px;
}

.node-gateway {

}

.node-gateway-text {
	top : 36px;
	left : 36px;
	width : auto;
	height : 25px;
	resize : none;
	overflow : hidden;
	position: absolute;
	border : transparent;
  background-color: transparent;
  color: white;
}

.node-gateway-text:hover {
	border : 2px solid rgb(169, 169, 169);
}

.jsplumb-overlay {
	background-color: whitesmoke;
}