@import "bootstrap";
@import "dashboard/common";
@import "dashboard-v2/common";
@import 'scheduler/common';
@import "w3o-users-roles/common";
@import "w3office-forms/common";

@import 'w3oprocess/common';
@import 'w3oprocess/component/process-canvas';
@import 'w3oprocess/component/node-style';

@import 'web-map/ol-common.scss';

@import "general-custom-style";

@import "header-nav";
@import "skeleton";

@import "ember-power-select";

$main-color: #f5f6f8;

/* source: https://stackoverflow.com/a/17756714 */
html, body {
  max-width: 100%;
  overflow-x: hidden;
  height: 100%;
}

body > .ember-view {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.transparent {
  background-color: transparent;
  border: none;
  color: black;
}

a:hover {
  text-decoration:none;
}

body {
  font-size: 14px;
  background-color : $main-color;
  /*font-family: myfonts;*/
  font-family: sans-serif;
}

.btn-main {
  background-color: $main-color;
}

hr {
  border-color: #999999;
  /*border-color: #ebebeb;*/
}

textarea {
  resize: none;
  box-sizing:border-box;
}

.ease-animate { // used on process-canvas
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

label {
  font-weight: bold;
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

.w-fit-content {
  width: fit-content;
}

//Standard Component: DatetimePicker
.datetime-picker {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group > .datetime-picker {
  flex: 1 1 auto;
  width: 1%;
}

.datetime-picker[disabled] {
  background-color: #e9ecef;
}

$breakpoint: 576px;

@media (max-width: #{$breakpoint}) {
    /* This block only applies to viewports <= #{$breakpoint} wide... */
    ::-webkit-scrollbar { 
        display: none; 
    }
    .mobile-box-shadow{
      box-shadow: 0 4px 6px 0 hsla(0,0%,0%,0.2);
      border-radius: 3%;
    }
    .mobile-container-center{
      -ms-flex-pack:center!important;
      justify-content:center!important
    }
    .icon {
      padding: 2px;
      border-radius: 4px;
      border: 1px solid transparent;
      background-color: transparent;
    }

    .icon:hover {
      border-radius: 4px;
      border: 1px solid transparent;
      background-color: transparent;
    }
    .brand-center-mobile {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.loading-cover {
  background: rgba(0, 0, 0, 0.5);
  z-index:50;
  position: absolute;
  width:100%;
  height: 100%;
  top: 0;
  left: 0;
}

/*
* Dashboard Style
*/

@media (max-width: 575.98px) {
  .nav-item-d2 {
    font-size: 5vw !important;
    width: 15vw !important;
  }
}

.nav-d2 {
}

.nav-item-d2 {
  display: inline-block;
  font-size: 1vw;
  letter-spacing: 3px;
  width: 13vw;
  margin-right: 0.2rem;
}

.nav-d2-active {
  border-top: 4px solid #1d3557;
}

.nav-d2-active a {
  color: #1d3557 !important;
  text-transform: uppercase;
}

.nav-d2-inactive a {
  color: #6c757d;
  text-transform: uppercase;
}

.row-d2-chart {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.d2-child-nav-item {
  letter-spacing: 1px !important;
}
