/* * * * * * * * * * * * * * * * * * * * 
* To collapse all press sequentially:  *
* cmd/ctrl + k                         *
* cmd/ctrl + 1                         *
* * * * * * * * * * * * * * * * * * * */

.shout-inline {
  padding: 5px;
  color: white;
  float: left;
  width: 20%;
}

.chart-inline {
  display: inline-block; 
  padding: 5px;
  float: left;
  width: 50%;
}

@media screen and (max-width: 768px){
  .shout-inline {
    width: 100% !important;
  }

  .chart-inline {
    width: 100% !important;
  }
  .blueArrow {
    padding: 10px 10px !important;
    font-size: initial !important;
  }
}

#kpi-top {
  // border: 1px solid gray; 
  padding: 5px;
  margin: 10px 0px 10px 0px;
  width: 100%;
  /*background-color: #eeeeee*/
}

#kpi-mid {
  // border: 1px solid gray; 
  padding: 5px;
  margin: 10px 0px 10px 0px;
  width: 100%;
  /*background-color: #eeeeee*/
}

#kpi-mid2 {
  // border: 1px solid gray; 
  padding: 5px;
  margin: 10px 0px 10px 0px;
  width: 100%;
  /*background-color: #eeeeee*/
}

/* ----------------- CAROUSEL -----------------*/
.carousel {
  position: relative;
  //height: 150px;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  /*background-color: #eeeeee;*/
}

.carousel-inner > .item {
  position: relative;
  display: none;
  //height: 150px;
  
  -webkit-transition: .6s ease-in-out left;
  -o-transition: .6s ease-in-out left;
  transition: .6s ease-in-out left;
}

.carousel-inner > .item > div,
.carousel-inner > .item > div {
  line-height: 1;
  //position: absolute;
  //top: 0;
  //left: 0;
  min-width: 100%;
  //height: 150px;
  padding: 5px;
  margin: 10px 0px 10px 0px;
}

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  //left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.controlsBlock {
  position: relative;
  bottom: 0;
  left: 0;
  top: 10px;
  width: 30%;
  display: block;
  margin: 0 auto 30px auto;
  z-index: 1;
  height: 24px;
  text-align: center;
}

.controls {
  position: relative;
  display: block;
}

.carousel-control {
  position: absolute;
  top: 25.5px;
  bottom: 0;
  left: 0;
  background: #000000;
  font-size: 5px;
  background: none !important;
  color: #000 !important;
  text-align: center;
}

.carousel-control i {
  font-size: 20px;
}

.carousel-control.left {
  left: 0;
}

.carousel-control.right {
  right: 0;
}

.carousel-control:hover,
.carousel-control:focus {
  color: #000;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  z-index: 5;
  display: inline-block;
}

.carousel-control .glyphicon-chevron-left {
  left: 0;
}

.carousel-control .glyphicon-chevron-right {
  right: 0;
}

.carousel-indicators {
  position: relative;
  bottom: 0;
  left: 50%;
  z-index: 15;
  width: 60%;
  top: 10px;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}


.carousel-indicators li {
  display: inline-block;
  zoom: 1;
  width: 10px;
  height: 10px;
  margin: 0 2px;
  background: transparent;
  background-color: #bbc2c1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid transparent;
  -webkit-box-shadow: rgba(0,0,0,0.5) 0 0 2px;
  -moz-box-shadow: rgba(0,0,0,0.5) 0 0 2px;
  box-shadow: rgba(0,0,0,0.5) 0 0 2px;
  cursor: pointer;
}

.carousel-indicators .active {
  width: 10px;
  height: 10px;
  margin: 0;
  background-color: #000000;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel {
    //height: 500px;
  }

  .carousel-inner > .item {
    //height: 500px;
  }

  .carousel-inner > .item > div,
  .carousel-inner > .item > div {
    //height: 500px;
  }

  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    font-size: 15px;
  }

  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }

  .carousel-indicators {
    bottom: .0;
  }
}

.panel-top {
  border-radius: 4px 4px 0 0;
  background-color: #e2e2e2;
  border-bottom: 1px solid #e3e3e3;
  padding: 15px 10px;
  margin: 0 0;
}

.carousel-indicators .active {
  background-color: #00abd6;
}

/* ----------------- End of CAROUSEL -----------------*/

/* ----------------- Advanced Component / Map Input -----------------*/

.leaflet-container, .map-container {
  height: 400px;
}

/* ----------------- End of Advanced Component / Map Input -----------------*/

/* ----------------- Dashboard Report -----------------*/
.dashboard-report-full{
  float: left;
  width: 100%;
}

.dashboard-report-half{
  float: left;
  width: 50%;
  padding: 5px 5px;
}
/* ----------------- End of Dashboard Report -----------------*/

/* ----------------- NOTIFICATION -----------------*/
.NotificationRoot {
  display: block;
  //background: 0 0;
  font: normal normal 14px/1.4 Helvetica,Roboto,"Segoe UI",Calibri,sans-serif;
  color: #292F33;
  white-space: normal;
  white-space: initial;
}

.notification-Widget {
  max-width: 1200px;
  background-color: #fff;
  border-radius: 4px;
}

.notification-Header {
  padding: 10px;
}

.notification-Header-title {
  font-size: 27px;
  line-height: 36px;
  margin-top: 10px;
}

.notification-Body {
  position: relative;
  border-top: 1px solid rgba(15,70,100,.12);
  border-bottom: 1px solid rgba(15,70,100,.12);
}

.notification-Viewport {
  overflow-x: hidden;
  overflow-y: scroll;
  display: block;
}

ol {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

.notification-NotificationList-tweet:first-of-type {
  border-top: none;
}

.notification-NotificationList {
  -webkit-padding-start: 20px;
  border-bottom: #ddd 1px solid;
  border-top: none;
}

.notification-Notification {
  padding: 10px;
  display: block;
}

.notification-Notification:hover {
  background-color: rgba(160,200,220,.12);
}

.notification-Notification-delete {
  font-size: 15.4px;
  padding: 3px 10px;
  display: block;
}

.u-floatRight {
  float: right!important;
}

.notification-Notification-text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  //white-space: pre-wrap;
  word-wrap: break-word;
  margin: 10px 0;
  padding: 0;
  list-style: none;
  border: none;
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

.notification-Metadata {
  padding-right: 35px;
  display: block;
  padding-bottom: 20px;
  color: #666;
}

.notification-Notification-timestamp {
  font-size: 12px;
  line-height: 18px;
  color: #e1e8ed;
}
/* ----------------- End of NOTIFICATION -----------------*/
