.sub-menu{
  padding-left: 20px;
}

.sub-menu .sub-menu{
  padding-left: 20px;
}

.caret-bar {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid;
    padding: 0px;
}

.caret-bar:hover {
  color: #ddd;
}