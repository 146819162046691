.chzn-container {
  font-size: 13px;
  position: relative;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.chzn-container .chzn-drop {
  background: #fff;
  border: 1px solid #aaa;
  border-top: 0;
  position: absolute;
  top: 29px;
  left: 0;
  -webkit-box-shadow: 0 4px 5px rgba(0,0,0,.15);
  -moz-box-shadow   : 0 4px 5px rgba(0,0,0,.15);
  -o-box-shadow     : 0 4px 5px rgba(0,0,0,.15);
  box-shadow        : 0 4px 5px rgba(0,0,0,.15);
  z-index: 1010;
}

/*multi*/
.chzn-container-multi .chzn-choices {
  background-color: #fff;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background-image: -webkit-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: -moz-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: -o-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: -ms-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  border: 1px solid #aaa;
  margin: 0;
  padding: 0;
  cursor: text;
  overflow: hidden;
  height: auto !important;
  height: 1%;
  position: relative;
}

.chzn-container-multi .chzn-choices li {
  float: left;
  list-style: none;
}

.chzn-container-multi .chzn-choices .search-field {
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

.chzn-container-multi .chzn-choices .search-field input {
  color: #666;
  background: transparent !important;
  border: 0 !important;
  font-family: sans-serif;
  font-size: 100%;
  height: 15px;
  padding: 5px;
  margin: 1px 0;
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow   : none;
  -o-box-shadow     : none;
  box-shadow        : none;
}

.chzn-container-multi .chzn-choices .search-field .default {
  color: #999;
}

.chzn-container-multi .chzn-choices .search-choice {
  -webkit-border-radius: 3px;
  -moz-border-radius   : 3px;
  border-radius        : 3px;
  -moz-background-clip   : padding;
  -webkit-background-clip: padding-box;
  background-clip        : padding-box;
  background-color: #e4e4e4;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#eeeeee', GradientType=0 ); 
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eeeeee));
  background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -ms-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%); 
  -webkit-box-shadow: 0 0 2px #ffffff inset, 0 1px 0 rgba(0,0,0,0.05);
  -moz-box-shadow   : 0 0 2px #ffffff inset, 0 1px 0 rgba(0,0,0,0.05);
  box-shadow        : 0 0 2px #ffffff inset, 0 1px 0 rgba(0,0,0,0.05);
  color: #333;
  border: 1px solid #aaaaaa;
  line-height: 13px;
  padding: 3px 20px 3px 5px;
  margin: 3px 0 3px 5px;
  position: relative;
  cursor: default;
}

.chzn-container-multi .chzn-choices .search-choice .search-choice-close {
  display: block;
  position: absolute;
  right: 3px;
  top: 4px;
  width: 12px;
  height: 13px;
  font-size: 1px;
  background: url('../images/chosen-sprite.png') right top no-repeat;
}
.chzn-container-multi .chzn-choices .search-choice .search-choice-close:hover {
  background-position: right -11px;
}
.chzn-container-multi .chzn-choices .search-choice-focus .search-choice-close {
  background-position: right -11px;
}
.chzn-disabled .chzn-choices .search-choice .search-choice-close {
  cursor: default;
}
.chzn-rtl .chzn-choices .search-choice .search-choice-close { left: 4px; right: auto; background-position: right top;}

.autocomplete-choice
{
  list-style-type: none;
  padding: 3px 7px 3px 7px;
  margin: 0px 3px 0px 3px;
}

.autocomplete-choice:hover
{
  background-color: #e4e4e4 !important;
  color: #000000 !important;
}