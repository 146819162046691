/*
Source: https://codepen.io/oslego/pen/XdvWmd
*/

/* 
Animated skeleton screen using CSS.

Create shapes using gradients that simulate solids.

Use `:empty` pseduo-class to show skeleton screen background only while container has no content (ex: for the "loading" state). When content is added to the container element, the pseudo-class selector won't match anymore and the skeleton screen will be removed automatically; no need to toggle a separate class on the container.

See: https://developer.mozilla.org/en-US/docs/Web/CSS/:empty

Animating one layer of the background which is a tilted linear gradient with white in the middle to achieve shine effect.
*/
.skeleton {
	margin: auto;
	height: 37px; /* change height to see repeat-y behavior */

	background-image:
		linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
		linear-gradient( lightgray 30px, transparent 0 );

	background-repeat: repeat-y;

	background-size:
		50px 50px, /* highlight */
		100% 200px;

	background-position:
		0 0, /* highlight */
		0 0;

	animation: shine 1s infinite;
}

@keyframes shine {
	to {
		background-position:
			100% 0, /* move highlight to right */
			0 0;
	}
}