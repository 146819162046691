#work-canvas {
  position: relative;
	overflow: auto; 
	max-height: 300px; 
	height: 300px; width: 96%;
	border: solid 1px rgba(51,51,51,0.12); 
	margin-left: 2%;
  background-color: #f5f5f5;
}

#view-canvas {
  position: relative;
  overflow: auto; 
  max-height: 300px; 
  height: 300px; width: 93.2%;
  border: solid 1px rgba(51,51,51,0.12); 
  margin-left: 2%;
  background-color: #f5f5f5;
}

#canvas-toolbar {
  margin-left: 2%; 
  width: 96%;
  background-color: #bdbdbd;
}

#canvas-option {
  white-space: nowrap;
  overflow-x: auto;
  margin-left: 2%; 
  width: 96%;
  background-color: #bdbdbd;
}

#canvas-option > div {
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
  
  /* for IE6/7, remove if you don't need to support those browsers */
  *display: inline;
  zoom: 1;
}

#process-desc {
  resize : none;
}

.toolbar-item {
  margin-left: 10px;
}

.bpmn-icon {
  max-height: 22px;
}

.bpmn-icon-menu {
  max-height: 22px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.row-toolbar {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
}

.endpoint-hover{
  cursor: pointer;
}

#edit-mode-btn {
  float: right;
  /*position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 0px;*/
}