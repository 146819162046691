.wizard .wizard-active {
	background-color: #008cba;
	color: white;
}

.wizard {
	background-color: white;

}

.wizard-form {
	padding-left: 20px;
	padding-right: 20px;
}


.wizard-wrapper {
	background-color: white;
	padding:15px;
}

.wizard-step {
	background-color: white;
	padding: 20px;
	cursor: pointer;
}
