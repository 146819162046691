/*
	Here the area for EXTEND and OVERRIDE the predefined
	CSS class from w3o-core
*/





/*
	Here the area for DEFINE NEW
	CSS class to custom the w3o-core
*/